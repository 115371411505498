// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-your-trip-js": () => import("/opt/build/repo/src/pages/book-your-trip.js" /* webpackChunkName: "component---src-pages-book-your-trip-js" */),
  "component---src-pages-destinations-js": () => import("/opt/build/repo/src/pages/destinations.js" /* webpackChunkName: "component---src-pages-destinations-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-shuttle-services-js": () => import("/opt/build/repo/src/pages/shuttle-services.js" /* webpackChunkName: "component---src-pages-shuttle-services-js" */),
  "component---src-pages-tours-js": () => import("/opt/build/repo/src/pages/tours.js" /* webpackChunkName: "component---src-pages-tours-js" */)
}

